.current-user {
    color: $sc-white;
    
    .rank-card__list {
        border-bottom: none;

        >div {
            background-color: $sc-navy;
        }

        .rank-card__list__place {
            background-color: $sc-black;
        }
    }
}

.current-user-rank {
    background-color: $sc-blue;
}

.mlr10 {
    margin-left: 12px;
    margin-right: 12px;
}

.rank-card__list {
    font-family: 'ClanOT-CondUltraItalic';
    border-bottom: 1px solid $sc-blue;

    a {
        color: black;
        text-decoration: none;
    }

    >div {
        padding-top: 6px;
        padding-bottom: 6px;
        @media (min-width: 1200px) {
            display: flex;
            align-items: center;
            flex-flow: row wrap;

            br {
                display: none;
            }
        }
    }

    .rank-card__list__heart {
        cursor: pointer;
    }

    @media (min-width: 1200px) {
        .rank-card__list__name {
            justify-content: space-between;

            span {
                flex: 50%;
            }
            
            flex: 1;
        }

        .rank-card__list__rank {
            width: 18%;
            display: flex;
            flex-flow: row-reverse wrap;
            justify-content: space-between;

            span:first-child {
                width: 30%;
            }
            span:last-child {
                width: 70%;
            }
        }

        &.rank-card__list__header {
            .rank-card__list__rank > span:first-child {
                width: calc(30% + 40px);
                margin-right: -40px;
                /* as the heart doesnt have anything in the header, this is okay. hard to fit it all otherwise. */
                z-index: 1;
            }
        }

        .rank-card__list__heart {
            img {max-width: 32px;}
            text-align: center;
        }
    }
    
    .rank-card__list__name span:last-child,
    .rank-card__list__rank span:last-child {
        font-family: 'ClanOT-Bold';
        font-size: 0.8rem;
    }

    .rank-card__list__nat {
        img {
            width: 100%;
            @media (max-width: 1199.98px) {
                width: calc(100% + 16px);
                margin-left: -4px; /* this is actually not centered, but visually, this looks right */
            }
        }
    }
    
    @media (max-width: 1199.98px) {
        .rank-card__list__heart img {            
            width: calc(100% + 16px);
            margin-left: -8px;
        }
    }

    @media (max-width: 767.98px) {
        font-size: 12px;
        .rank-card__list__name span:last-child, .rank-card__list__rank span:last-child {
            font-size: 10px;
        }
    }

    &.rank-card__list__header {
        border-bottom: none;
        color: $sc-white;
        margin-top: 12px;

        >div {
            background-color: $sc-blue;
        }

        .rank-card__list__place {
            background-color: $sc-black;
        }
    }
}

.filter-bar {
    display: none;
}

label[for="filtercheck"] {
    color: $sc-blue;
    text-decoration: underline;
    margin-top: 4px;
}

#filtercheck:checked ~ .filter-bar {
    display: block;
}

.ranking__filter {
    label {
        color: $sc-blue;
    }
}

label[for="rank-favorites"] {
    width: 32px;
    color: black;
    margin-top: 12px;

    #Lager_1 g .st1 {
        display: none;
    }
}

#rank-favorites:checked ~ label[for="rank-favorites"] #Lager_1 g .st1 {
    display: block;
}