$sc-blue: #009FE3;
$sc-cyan: #57C2E0;
$sc-navy: #3762A2;
/* possibly unused */
$sc-red: #ff0000;
$sc-gold: #B28712;
$sc-dark: #1A1A1A;
$sc-black: #000;
$sc-white: #FFF;
$sc-grey: #CCC;
$sc-gradient: linear-gradient(180deg, #5198c6, #000d2d);

$text-main-xxl: 3rem;
$text-main-xl: 2rem;
$text-main-l: 1.5rem;
$text-main-m: 1.25rem;
$text-main-s: 1rem;
$text-main-xs: 0.8rem;
 
$date-l: 3rem;
$date-m: 2rem;
$date-s: 0.8rem;
 
$rank-number-l: 1.2rem;
$rank-number-m: 1.1rem;
$rank-number-s: 0.8rem;
 

@font-face {
    font-family: ClanOT-Bold;
    src: url("/fonts/clanot/ClanOT-Bold.woff2");
    font-display: swap;
}

@font-face {
    font-family: ClanOT-Book;
    src: url("/fonts/clanot/ClanOT-Book.woff2");
    font-display: swap;
}

@font-face {
    font-family: ClanOT-CondUltraItalic;
    src: url("/fonts/clanot/ClanOT-CondUltraItalic.woff2");
    font-display: swap;
}

@font-face {
    font-family: ClanOT-News;
    src: url("/fonts/clanot/ClanOT-News.woff2");
    font-display: swap;
}

@font-face {
    font-family: ClanOT-Ultra;
    src: url("/fonts/clanot/ClanOT-Ultra.woff2");
    font-display: swap;
}

@font-face {
    font-family: ClanOT-CondNews;
    src: url("/fonts/clanot/ClanOT-CondNews.woff2");
    font-display: swap;
}

@font-face {
    font-family: ClanSC-CondBold;
    src: url("/fonts/clansc/ClanSCOffcProCondBold.woff2");
    font-display: swap;
}

@font-face {
    font-family: ClanSC-CondUltra;
    src: url("/fonts/clansc/ClanSCOffcProCondUltra.woff2");
    font-display: swap;
}

@font-face {
    font-family: ClanSC-CondUltraItalic;
    src: url("/fonts/clansc/ClanSCOffcProCondUltraItalic.woff2");
    font-display: swap;
}

:root {
    --bs-gutter-x: 40px !important;
    --bs-font-sans-serif: 'ClanOT-Bold', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-info-rgb: 0, 159, 227;
    --bs-danger-rgb: 255, 0, 0;
}

html, body {
    height: 100%; /* Make sure the body covers the full viewport */
    margin: 0;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;   
}

.tf {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.sc-button {
    color: $sc-white;
    background-color: $sc-blue;
    padding: 2px 8px;
    text-decoration: none;
    margin-right: 6px;
    font-family: 'ClanOT-CondUltraItalic';
    border: none;
    cursor: pointer;

    &.sc-button__grey {
        background-color: $sc-grey;
    }

    &.sc-button__red {
        background-color: $sc-red;
    }
}

.sc-table {
    text-transform: uppercase;
    padding-left: 0;
    padding-right: 0;
    margin-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: calc(var(--bs-gutter-x) * 0.5);

    >.row {
        border-top: 1px solid $sc-blue;
        align-items: center;
    }

    >.row div {
        margin: 6px 0px;
        font-size: $text-main-s;
        font-family: 'ClanOT-Bold';
        text-transform: uppercase;
        word-break: break-all;
    }

    >.row div:last-child:not(:first-child),
    >.row div:last-child:not(:first-child) * {
        font-size: $text-main-m;
        font-family: 'ClanOT-CondUltraItalic';
    }

    >.row div .sc-button {
        float: right;
        margin-right: 0px;
        margin-left: 6px;
        margin-top: -2px;
        margin-bottom: -2px;
    }

    &.reverse {
        >.row {
            border-bottom: 1px solid $sc-blue;
            border-top: unset;
        }
    }
}


.sc-pin {
    height: 32px;
    width: 32px;
    object-fit: contain;
    display: inline-block;

    img {
        height: 100%;
    }
}

.sc-pin-big {
    height: 75px;
    width: 75px;
    object-fit: contain;
    display: inline-block;

    img {
        height: 100%;
    }
}

.sc-pin-popup img {
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: scale(1.28);
        background-color: white;
        box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
        cursor: pointer;
    }
}

.sc-pin-modal {
    display: flex !important; /* :/ */
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

    --bs-modal-border-width: 0px;
    --bs-modal-border-radius: 0px;
    --bs-modal-box-shadow: none;

    .btn-close {
        --bs-btn-close-hover-opacity: 1.0;
        --bs-btn-close-bg: url("/images/icons/x_pos.svg");
        box-shadow: none;
        padding: 0px;
        opacity: 1.0;
        width: 32px;
        height: 32px;
        background: transparent var(--bs-btn-close-bg) center/32px auto no-repeat
    }

    .modal-header {
        border: none;
    }

    .modal-body {
        text-align: center;
    }
}

.sc-number {
    font-family: 'ClanOT-Ultra';
}

.sc-blue {
    color: $sc-blue;
}
.sc-black {
    color: $sc-black;
}


.sc-nag-a {
    text-decoration: none;
}
.sc-nag {
    min-height: 80px;
    background-color: $sc-black;
    color: $sc-blue;
    display: flex;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 2px;
    padding-right: 16px;
    line-height: 1;
    font-family: "ClanOT-CondUltraItalic";
    font-size: 1.5rem;
    word-break: break-word;

    span {
        color: $sc-white;
    }

    img {
        width: 80px;
    }
}

.sc-nag-big {
    min-height: 220px;
    flex-flow: column;
    align-items: flex-start;
    padding: 16px;
    font-size: 1.75rem;
    
    img {
        flex-grow: 1;
        object-fit: contain;
        object-position: top;
    }
}

.sc-nag-praise {
    background-color: $sc-blue;
    color: $sc-black;

    a {
        color: $sc-white;
    }

    .sc-nag-smalltext {
        font-size: 1rem;
        font-family: "ClanOT-Bold";
    }
}

a {
    color: $sc-blue;
    font-style: normal;
}

/* summary/detail but in bootstrap accordion */

details {
    margin-bottom: 6px;
}

summary {
    background-color: $sc-black;
    color: $sc-white;
    padding: 2px 8px;
    padding-bottom: 0px;
    /* cuz of how font rendering works.... */
    font-size: $text-main-m;
    list-style: none;
    margin-bottom: 6px;
    font-family: 'ClanOT-CondUltraItalic';
    user-select: none;
}

summary::after {
    float: right;
    content: '▼';
    font-style: unset;
    font-weight: normal;
    transform: rotateZ(-90deg);
    /* the > icon looks bad. */
    /* maybe replace this with an image or proper icon? */
}

details[open] summary::after {
    content: '▼';
    transform: unset;
}

details[disabled] {
    pointer-events: none;
}

details[disabled] summary::after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    background-image: url("/images/icons/mypages_dbconnect_pos.svg");
    background-repeat: no-repeat;
    transform: unset;
    margin-left: -4px;
    margin-top: 1px;
}

:root:root:root .accordion,
:root:root:root .accordion-button {
    --bs-accordion-color: $sc-black !important;
    --bs-accordion-bg: transparent !important;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease !important;
    --bs-accordion-border-color: transparent !important;
    --bs-accordion-border-width: 0px !important;
    --bs-accordion-border-radius: 0px !important;
    --bs-accordion-inner-border-radius: 0px !important;
    --bs-accordion-btn-padding-x: 8px !important;
    --bs-accordion-btn-padding-y: 6px !important;
    --bs-accordion-btn-color: $sc-white !important;
    --bs-accordion-btn-bg: $sc-black !important;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='2.5 2.5 15 15' fill='%23ffffff' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M15 10l-9 5V5l9 5z'/%3e%3c/svg%3e") !important;
    --bs-accordion-btn-icon-width: 1.25rem !important;
    --bs-accordion-btn-icon-transform: rotate(90deg) !important;
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out !important;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='2.5 2.5 15 15' fill='%23ffffff' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M15 10l-9 5V5l9 5z'/%3e%3c/svg%3e") !important;
    --bs-accordion-btn-focus-box-shadow: unset !important;
    --bs-accordion-body-padding-x: 0px !important;
    --bs-accordion-body-padding-y: 6px !important;
    --bs-accordion-active-color: $sc-white !important;
    --bs-accordion-active-bg: $sc-black !important;

    .accordion-header button {
        font-weight: bolder;
        font-style: italic;
        text-transform: uppercase;
    }
}

.accordion {
    margin-bottom: 6px;

    .accordion-body {
        padding-bottom: 0px;
    }
}

/* search box ( blade ? ) could use some tweaking */
.sfield-wrapper {
    --bs-border-color: $sc-black;

    .sfield {
        &::placeholder {
            color: $sc-black;
        }

        &:focus {
            &::placeholder {
                opacity: 0;
            }

            background-color: transparent;
            color: $sc-black;
            outline: none;
            border-color: $sc-black;
        }

        color: $sc-black;
        border-radius: 0px;
        background-color: $sc-white;
    }

    .btn {
        border: none;
        background-image: url("/images/icons/search_pos.svg");
        background-repeat: none;
        width: 38px;
    }

    .btn-light {
        background-image: url("/images/icons/search_neg.svg");
    }

    .search {
        &:hover {
            background-color: $sc-black;
        }

        color: $sc-white;
        background-color: $sc-black;
        border-radius: 0px;
    }

    .sfield::-ms-input-placeholder {
        /* Edge */
        color: $sc-black;
    }
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    color: $sc-white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'ClanOT-News';
    text-transform: uppercase;
    background-color: $sc-black;
    background-image: none;
    border: 1px solid $sc-black;
    border-radius: 0px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.spinner-border {
    color: $sc-blue!important;
}

/* ikon-a ( blade ? ) */
.icon-a {
    color: $sc-black;
    text-decoration: none;
    font-family: 'ClanOT-News';
    height: 24px;

    img {
        height: 24px;
    }
}

@media (max-width: 1199.98px) { 
    .desktop {
        display: none;
    }
}

@media (min-width: 1200px) {
    .mobile {
        display: none;
    }
}