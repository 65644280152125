// TODO: inte klar
main:has(.back) {
    background-color: #1A1A1A;
}
.back {
    background-color: #1A1A1A;
    color: white;
    height: 100%;
}

.proam-title {
    font-size: $text-main-xl;
    font-family: ClanOT-CondUltraItalic;
}

.proam-bold {
    font-size: $text-main-s;  
    font-family: ClanOT-CondUltraItalic;
    text-transform: uppercase;
}

.pro-am-knapp {
    text-align: start;
    background-color: black;
    border: none;
    color:white;
}

.pro-am-bar {
    color: black;
    background-color: $sc-blue;
    padding: 0.4em;
}

.proam-teampic {
    float: right;
    text-align: right;
    
    .pro-am-teambild {
        width: 70%;
        max-width: 96px;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }
}

.proam-member {
    >div:first-child {
        width: unset;
    }
    >div:last-child {
        flex: 1;
    }
}

.pro-am-profilbild {
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
}

.pro-am-smalltext {
    font-size: $text-main-xs;
}

.proam-small {
    font-size: 10px;
}

.blue {
    color: $sc-blue;
}

.pro-am-teamleader {
    font-size: 13px;
    color: white;
}

.pro-am-add {
    font-size: 12px;
    text-align: start;
    background-color: $sc-blue;
    border: none;
    color:white;
}

.proam-flag {
    width: 80%;
}

.proam-pic {
    border-radius: 50%;
}

.proam-goodteampfp {
    width: unset;
    padding-right: 8px;
}

.search-card {
    font-size: 14px;
    border-bottom: $sc-blue 1px solid;
}

.proam-decline {
    background-color: $sc-red;
}

.dark {
    .sfield {
        &::placeholder {
            color: white;
        }
        &:focus {
            color: white;
            border-color: white;
        }
        // border: white;
        border-color: white;
        color: white;
        background-color: #1A1A1A;
    }

    .search {
        &:hover {
            background-color: white;
        }
        background-color: white;
        color: black;
    }

    .sfield::-ms-input-placeholder { /* Edge */
        color: white;
    }}

.proam-input {
    input {
        border-radius: 0;
        border: 0;
        background-color: $sc-blue;
        &:focus {
                background-color: $sc-blue;
        }
    }
}

.proam-file {
    input {
        background-color: $sc-blue;
        border: none;
        &:focus {
            background-color: $sc-blue;
        }
    }
}

.proam-member {
    align-items: center;
}

#proam-createbtn {
    border: none;
    background-color: $sc-blue;
    color: white;
    padding: 0.3rem;
    width: fit-content;
    &:disabled {
        background-color: dimgrey;
        color: linen;
        opacity: 1;
    }
}

.proam-btn {
    text-align: center;
    letter-spacing: .1rem;
}

.proam-create {
    .ps-1 {
        font-family: "ClanOT-Bold";
        font-size: $text-main-s;
    }
}

#results .search-card div:last-child {
    justify-content: flex-end;
}

@media (min-width: 1200px) { 
    main {
        padding: 0;
    }
    main > form > .container, main > .container {
        padding: 24px;
    }

    .proam-left {
        width: calc(50% - 24px);
        margin-right: 24px;
    }

    .sfield-wrapper {
        margin-top: 0 !important;
    }
}