.sc-mymessages__messages {
    .sc-mymessages__messages__message {
        border-bottom: 1px solid $sc-blue;
        margin-bottom: 12px;
        padding-bottom: 12px;

        span {
            display: inline-block;
        }

        .sc-mymessages__messages__message__datebox {
            color: $sc-blue;
            text-decoration: underline; /* ????????????????? */
            display: flex;
            align-items: center;

            span {
                flex-grow: 1;
            }

            img {
                height: 32px;
            }
        }

        .sc-mymessages__messages__message__title {
            font-size: 1.5rem;
            font-family: 'ClanOT-CondUltraItalic';
        }

        .sc-mymessages__messages__message__content {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        .sc-button {
            border: none;
        }

    }
}