.sc-membership__paydisplay {
    background-color: $sc-black;
    color: $sc-white;
    padding: 2px 8px;
    padding-bottom: 0px; /* cuz of how font rendering works.... */
    font-size: $text-main-m;
    list-style: none;
    margin-bottom: 6px;
    font-family: 'ClanOT-CondUltraItalic';

    span {
        float: right;
        background-color: $sc-gold;
        height: 100%;
        margin-right: -8px;
        padding: 0px 8px;
        padding-top: 2px;
        margin-top: -2px;
    }
}

.sc-membership__payments {
    overflow: hidden;

    .sc-membership__payments__payment {
        border-bottom: 1px solid $sc-blue;
        margin-bottom: 12px;
        padding-bottom: 12px;

        .sc-membership__payments__payment__date {
            margin-bottom: 6px;

            span {
                float: right;
            }
        }

        .sc-membership__payments__payment__info {
            display: flex;
            align-items: center;
            height: 40px;
            font-family: "ClanOT-CondUltraItalic";
            font-size: $text-main-m;

            img {
                height: 40px;
                margin-right: 16px;
            }

            span {
                flex: 1;
            }

            .sc-button {
                font-size: 1rem;
                margin-right: 0px;
            }
        }
    }
}

.membership-toggle {
    cursor: pointer;
    .spinner-border {
        float: right;
    }
}