.sc-results {
    .sc-results__results {
        overflow: hidden;

        .col-3 {
            display: flex;
            align-items: center;

            img {
                width: 100%;
                max-height: 96px;
                object-fit: contain;
            }
        }
        .col {
            display: flex;
            flex-flow: column wrap;

            div {
                display: flex;
                justify-content: space-between;
            }

            div:nth-child(2) {
                flex: 1;
            }
        }

        .sc-results__results__result { /* consider renaming this. */
            border-bottom: 1px solid $sc-blue;
            margin-bottom: 6px;
            padding-bottom: 6px;

            &:last-child {
                border-bottom: none;
            }
        }

        .sc-results__results__result__tour {
            color: $sc-red;
            font-size: $text-main-xs;
        }
        .sc-results__results__result__tour__cyan {
            color: $sc-cyan;
            font-size: $text-main-xs;
        }
        .sc-results__results__result__title {
            font-size: 1.5rem;
            text-transform: uppercase;
            font-family: 'ClanOT-CondUltraItalic';
            margin-bottom: -4px;
        }
        .sc-results__results__result__placepins {
            margin-bottom: 4px;
        }
    }

    .sc-results__upcoming {
        .sc-results__upcoming__race {
            border-bottom: 1px solid $sc-blue;
            margin-bottom: 6px;
            padding-bottom: 6px;
            margin-left: 0;
            margin-right: 0;

            .col {
                display: flex;
                flex-flow: column;    
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .sc-results__upcoming__race__date {
            background-color: $sc-blue;
            color: $sc-white;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            height: 86px;
            width: 86px;
            @media (min-width: 1200px) { 
                height: 96px;
                width: 96px;
                span:last-child {
                    font-size: $text-main-xs;
                }
            }

            span:first-child {
                font-size: $text-main-xxl;
                font-family: "ClanSC-CondUltra";
                margin-bottom: -12px;
            }
            span:last-child {
                font-family: "ClanOT-CondNews";
                font-size: 0.7rem;
            }
        }

        .sc-results__upcoming__race__tour {
            color: $sc-red;
            font-size: $text-main-xs;
        }
        .sc-results__upcoming__race__tour__cyan {
            color: $sc-blue;
            font-size: $text-main-xs;
        }
        .sc-results__upcoming__race__title {
            flex: 1;
            font-size: $text-main-l;
            text-transform: uppercase;
            font-family: 'ClanOT-CondUltraItalic';
        }
        .sc-results__upcoming__race__info {
            font-family: 'ClanOT-News';
            font-size: $text-main-xs;
        }
        .sc-results__upcoming__race__share {
            float: right;
        }
    }
}