.login-wrapper {
    background-color: $sc-blue;
    font-family: ClanOT-Ultra;
}

.login-page__title {
    font-size: $text-main-xl;
    margin-top: 3rem;
}

.login-logo {
    img {
        height: 100%;
        width: 100%;
    }
    height: 160px;
    width: 160px;
}

.login-btn {
    font-size: $text-main-s;
    background-color: $sc-blue;
    color: white;
    text-decoration: none;
    padding: 10px;
}

.login-page__background { 
    background-color: $sc-blue;
}