.sc-admin {
    line-height: 2;

    button {
        appearance: none;
        background-color: var(--bs-body-bg);
        background-clip: padding-box;
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
        background-color: #ddd;
    }

    font-size: 16px;
    * {
        font-size: 16px;
    }
}

.pins-form {
    font-size: 16px;
    * {
        font-size: 16px;
    }
}

.pins-form .form-group {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $sc-blue;
}

.pins-form .form-select {
    width: unset;
    padding: 0.3rem;
    text-align: left;
    color: $sc-black;
    text-transform: none;
    background-color: $sc-white;
    border: 1px solid $sc-black;
    border-radius: 4px;
}