.menu-item {
    user-select: none;
    font-size: $text-main-s;
    a {
        color: $sc-black !important;
        text-decoration: none !important;
    }

    .mp-menu-links {
        display: none;
    }
    .mp-menu-info {
        display: flex;
        font-size: $text-main-xs;
    }
    
}
.menu-title {
    font-family: ClanSC-CondUltraItalic;
    font-size: $text-main-xl;
    margin-bottom: -8px;
}

main {
    padding-top: 12px;
}

.login-page__background, main:has(.proam-title) {
    margin-top: 0px;
}

.sc-play {
    a {
        color: $sc-black !important;
        text-decoration: none !important;
    }

    .text-bg-danger {
        display: flex;
        flex-flow: column;
        text-align: center;
    }
    
    .menu-text-mini {
        font-family: ClanOT-CondNews;
        font-size: $date-s;
        font-weight: bolder;
    }
    
    .menu-text-date {
        font-family: ClanSC-CondUltra;
        font-size: $date-l;
        margin-top: -32px;
        margin-bottom: -32px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex-grow: 1;
    }
}

@media (max-width: 1199.98px) {
    .sc-play {
        max-height: 120px;
        margin-bottom: 0.5rem;
    
        > a {
            > .col-3 {
                max-width: 120px;
            }
        
            > .col-6 {
                flex: 1;
            }
            
            > .text-bg-danger {
                max-width: 120px;
            }
        }
    }
}

.menu-item:active, .menu-item:hover, .menu-item.current {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    --bs-border-opacity: 1;
    border-color: $sc-red !important;
    border-width: 10px !important; 
    @media (min-width: 1200px) { 
        .mp-menu-links {
            display: flex;
        }
        .mp-menu-info {
            display: none;
        }
    }
}

body {
    background-color: $sc-blue;
}
main {
    background-color: white;
}

@media (max-width: 1199.98px) {
    .showMobileMenu {
        height: 100vh;

        #navbarToggleExternalContent {
            display: block;
        }

        .navbar-nav li:last-child {
            display: none;
        }
    }

    .showMobileMenu ~ main {
        display: none;
    }
}

@media (min-width: 1200px) { 
    body {
        background-color: $sc-blue;
        flex-direction: row;
        gap: 12px;
        margin: auto;
        margin-top: 110px;
        max-width: 1600px;
        width: 100%;
    
        > div.container {
            max-width: 430px;

            nav.navbar {
                width: 100%;
                height: 110px;
                position: absolute;
                top: 0;
                padding-left: calc(max((100vw - 1600px) / 2, 0px) + var(--bs-gutter-x) * 0.5);
                padding-right: calc(max((100vw - 1600px) / 2, 0px) + var(--bs-gutter-x) * 0.5);
                width: 100vw;
                height: 110px;
                left: 0;
                
                > div {
                    padding: 0;
                }

                .navbar-nav {
                    align-items: center;
                }

                .navbar-nav li:last-child {
                    display: none;
                }

                .nav-item img, .navbar-brand img {
                    width: 64px;
                    height: 64px;
                }

                #cart {
                    width: 50px;
                    height: 50px;
                }
            }

            #navbarToggleExternalContent {
                display: block;
            }
        }
    }

    main {
        background-color: $sc-white;
        height: fit-content;
        padding: 12px;
        margin-right: 12px;
        overflow: auto;
        margin-top: 0;
        max-height: calc(100vh - 110px);
    }
}