.pin-header {
    border-bottom: 1px solid $sc-blue;
    border-top: 1px solid $sc-blue;
}

.pin-image {
    flex: 0 0 20%;
    max-width: 20%;
    justify-content: center;
    height: 75px;

    img {
        height: 75px;
        width: 75px;
        object-fit: contain;
    }
}

@media (min-width: 1200px) { 
    .pin-image {
        flex: 0 0 10%;
        max-width: 10%;
    }
}

.pin-row {
    min-height: 75px;
}

.img-fluid {
    max-height: 75px;
}