.sc-profile__top-stats {
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;

    background-color: black;
    color: white;
    padding: 12px; /* TODO: adjust top-padding after we get the font */

    .col {
        text-align: center;

        .sc-number {
            color: $sc-blue;
            font-size: $date-m;
        }

        font-size: $text-main-xs;
    }
}

.sc-profile__user {
    .sc-profile__user__profile-picture {
        max-width: 128px;
        display: grid;

        > * {
            grid-column: 1;
            grid-row: 1;
        }

        > div {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .sc-profile__user__profile-picture__img {
            width: 100%;
            border-radius: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }
        
        .sc-profile__user__profile-picture__flag {
            width: 44px;
            filter: drop-shadow(-2px 2px 4px rgba(0,0,0,0.5));
            /* image-rendering: pixelated; would make the flag look sharper, but our icons are bad, so it looks worse.... */
        }
    }

    .sc-profile__user__details {
        text-transform: uppercase;
        font-family: 'ClanOT-CondUltraItalic';
        display: flex;
        flex-flow: column;

        p {
            margin: 0;
            color: $sc-blue;
            font-size: $text-main-xl;
            margin-bottom: -12px;
        }

        .sc-profile__user__details__pins {
            flex-grow: 1;
            gap: 4px;
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap; /* ehhhhh */

            img {
                object-fit: contain;
                width: 100%;
            }

            @media (max-width: 1199.98px) {
                gap: 8px;
                margin-left: 8px;

                img {
                    width: 48px;
                    height: 48px;
                    margin: -16px;
                }
            }
        }
    }

}

.sc-profile__results {
    overflow: hidden;

    .sc-profile__results__result {
        border-bottom: 1px solid $sc-blue;
        margin-bottom: 6px;
        padding-bottom: 6px;

        .col-3 {
            display: flex;
            align-items: center;

            img {
                width: 100%;
            }
        }
        .col {
            div {
                display: flex;
                justify-content: space-between;
            }

            div:nth-child(2) {
                flex: 1;
            }

            display: flex;
            flex-flow: column wrap;
        }

        .sc-profile__results__result__tour {
            font-size: $text-main-xs;
            color: $sc-red;
            text-align: end;
        }
        .sc-profile__results__result__title {
            font-size: 1.5rem;
            text-transform: uppercase;
            font-family: 'ClanOT-CondUltraItalic';
            margin-top: -8px;
        }
        .sc-profile__results__result__place {
            font-size: 1.5rem;
            color: $sc-blue;
        }
        .sc-profile__results__result__pins {
            color: $sc-blue;
        }
    }
}

.sc-profile__pins .sc-pin {
    height: unset;
    display: flex;
    align-items: center;
    width: 20%;

    img {
        object-fit: contain;
        width: 100%;
    }
}

@media (min-width: 1200px) { 
    .sc-profile__user .sc-profile__user__details {
        flex-grow: 1;

        p {
            font-size: 3rem;
            margin-top: 12px;
            margin-bottom: -8px;
            line-height: 1;
        }

        .sc-profile__user__details__pins {
            flex-grow: unset;
            margin-top: 24px;
        }
    }

    .sc-profile__pins .sc-pin {
        width: 10%;
    }

    .sc-profile__user__profile-picture {
        max-width: 170px;
    }

    .sc-profile__user__details__pins {
        .sc-pin {
            width: 48px;
            height: 48px;
        }
    }

    .sc-profile__user__top-stats {
        align-items: flex-end;
        display: flex;
        max-width: 370px;

        >div {
            background-color: transparent;
            color: $sc-black;
        }
    }
}

.sc-editProfile {
    .sc-button {
        display: inline-block;
    }

    .hide, .text--hide {
        display: none;
    }

    .sc-profileEdit__pfp {
        width: 128px;
        height: 128px;
        border-radius: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    #image-preview {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 1 / 1;
    }

    .profile-input {
        border-radius: 0px;
        border: 1px solid black;
        color: black;
    }

    .alert {
        border-radius: 0px;
    }
}