@charset "UTF-8";
@import url(bootstrap.css);
/* possibly unused */
@font-face {
  font-family: ClanOT-Bold;
  src: url("/fonts/clanot/ClanOT-Bold.woff2");
  font-display: swap; }

@font-face {
  font-family: ClanOT-Book;
  src: url("/fonts/clanot/ClanOT-Book.woff2");
  font-display: swap; }

@font-face {
  font-family: ClanOT-CondUltraItalic;
  src: url("/fonts/clanot/ClanOT-CondUltraItalic.woff2");
  font-display: swap; }

@font-face {
  font-family: ClanOT-News;
  src: url("/fonts/clanot/ClanOT-News.woff2");
  font-display: swap; }

@font-face {
  font-family: ClanOT-Ultra;
  src: url("/fonts/clanot/ClanOT-Ultra.woff2");
  font-display: swap; }

@font-face {
  font-family: ClanOT-CondNews;
  src: url("/fonts/clanot/ClanOT-CondNews.woff2");
  font-display: swap; }

@font-face {
  font-family: ClanSC-CondBold;
  src: url("/fonts/clansc/ClanSCOffcProCondBold.woff2");
  font-display: swap; }

@font-face {
  font-family: ClanSC-CondUltra;
  src: url("/fonts/clansc/ClanSCOffcProCondUltra.woff2");
  font-display: swap; }

@font-face {
  font-family: ClanSC-CondUltraItalic;
  src: url("/fonts/clansc/ClanSCOffcProCondUltraItalic.woff2");
  font-display: swap; }

:root {
  --bs-gutter-x: 40px !important;
  --bs-font-sans-serif: 'ClanOT-Bold', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-info-rgb: 0, 159, 227;
  --bs-danger-rgb: 255, 0, 0; }

html, body {
  height: 100%;
  /* Make sure the body covers the full viewport */
  margin: 0;
  display: flex;
  flex-direction: column; }

main {
  flex: 1; }

.tf {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0; }

.sc-button {
  color: #FFF;
  background-color: #009FE3;
  padding: 2px 8px;
  text-decoration: none;
  margin-right: 6px;
  font-family: 'ClanOT-CondUltraItalic';
  border: none;
  cursor: pointer; }
  .sc-button.sc-button__grey {
    background-color: #CCC; }
  .sc-button.sc-button__red {
    background-color: #ff0000; }

.sc-table {
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0;
  margin-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: calc(var(--bs-gutter-x) * 0.5); }
  .sc-table > .row {
    border-top: 1px solid #009FE3;
    align-items: center; }
  .sc-table > .row div {
    margin: 6px 0px;
    font-size: 1rem;
    font-family: 'ClanOT-Bold';
    text-transform: uppercase;
    word-break: break-all; }
  .sc-table > .row div:last-child:not(:first-child),
  .sc-table > .row div:last-child:not(:first-child) * {
    font-size: 1.25rem;
    font-family: 'ClanOT-CondUltraItalic'; }
  .sc-table > .row div .sc-button {
    float: right;
    margin-right: 0px;
    margin-left: 6px;
    margin-top: -2px;
    margin-bottom: -2px; }
  .sc-table.reverse > .row {
    border-bottom: 1px solid #009FE3;
    border-top: unset; }

.sc-pin {
  height: 32px;
  width: 32px;
  object-fit: contain;
  display: inline-block; }
  .sc-pin img {
    height: 100%; }

.sc-pin-big {
  height: 75px;
  width: 75px;
  object-fit: contain;
  display: inline-block; }
  .sc-pin-big img {
    height: 100%; }

.sc-pin-popup img {
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; }
  .sc-pin-popup img:hover {
    transform: scale(1.28);
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    cursor: pointer; }

.sc-pin-modal {
  display: flex !important;
  /* :/ */
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  --bs-modal-border-width: 0px;
  --bs-modal-border-radius: 0px;
  --bs-modal-box-shadow: none; }
  .sc-pin-modal .btn-close {
    --bs-btn-close-hover-opacity: 1.0;
    --bs-btn-close-bg: url("/images/icons/x_pos.svg");
    box-shadow: none;
    padding: 0px;
    opacity: 1.0;
    width: 32px;
    height: 32px;
    background: transparent var(--bs-btn-close-bg) center/32px auto no-repeat; }
  .sc-pin-modal .modal-header {
    border: none; }
  .sc-pin-modal .modal-body {
    text-align: center; }

.sc-number {
  font-family: 'ClanOT-Ultra'; }

.sc-blue {
  color: #009FE3; }

.sc-black {
  color: #000; }

.sc-nag-a {
  text-decoration: none; }

.sc-nag {
  min-height: 80px;
  background-color: #000;
  color: #009FE3;
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 2px;
  padding-right: 16px;
  line-height: 1;
  font-family: "ClanOT-CondUltraItalic";
  font-size: 1.5rem;
  word-break: break-word; }
  .sc-nag span {
    color: #FFF; }
  .sc-nag img {
    width: 80px; }

.sc-nag-big {
  min-height: 220px;
  flex-flow: column;
  align-items: flex-start;
  padding: 16px;
  font-size: 1.75rem; }
  .sc-nag-big img {
    flex-grow: 1;
    object-fit: contain;
    object-position: top; }

.sc-nag-praise {
  background-color: #009FE3;
  color: #000; }
  .sc-nag-praise a {
    color: #FFF; }
  .sc-nag-praise .sc-nag-smalltext {
    font-size: 1rem;
    font-family: "ClanOT-Bold"; }

a {
  color: #009FE3;
  font-style: normal; }

/* summary/detail but in bootstrap accordion */
details {
  margin-bottom: 6px; }

summary {
  background-color: #000;
  color: #FFF;
  padding: 2px 8px;
  padding-bottom: 0px;
  /* cuz of how font rendering works.... */
  font-size: 1.25rem;
  list-style: none;
  margin-bottom: 6px;
  font-family: 'ClanOT-CondUltraItalic';
  user-select: none; }

summary::after {
  float: right;
  content: '▼';
  font-style: unset;
  font-weight: normal;
  transform: rotateZ(-90deg);
  /* the > icon looks bad. */
  /* maybe replace this with an image or proper icon? */ }

details[open] summary::after {
  content: '▼';
  transform: unset; }

details[disabled] {
  pointer-events: none; }

details[disabled] summary::after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background-image: url("/images/icons/mypages_dbconnect_pos.svg");
  background-repeat: no-repeat;
  transform: unset;
  margin-left: -4px;
  margin-top: 1px; }

:root:root:root .accordion,
:root:root:root .accordion-button {
  --bs-accordion-color: #000 !important;
  --bs-accordion-bg: transparent !important;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease !important;
  --bs-accordion-border-color: transparent !important;
  --bs-accordion-border-width: 0px !important;
  --bs-accordion-border-radius: 0px !important;
  --bs-accordion-inner-border-radius: 0px !important;
  --bs-accordion-btn-padding-x: 8px !important;
  --bs-accordion-btn-padding-y: 6px !important;
  --bs-accordion-btn-color: #FFF !important;
  --bs-accordion-btn-bg: #000 !important;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='2.5 2.5 15 15' fill='%23ffffff' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M15 10l-9 5V5l9 5z'/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-icon-width: 1.25rem !important;
  --bs-accordion-btn-icon-transform: rotate(90deg) !important;
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='2.5 2.5 15 15' fill='%23ffffff' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M15 10l-9 5V5l9 5z'/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-focus-box-shadow: unset !important;
  --bs-accordion-body-padding-x: 0px !important;
  --bs-accordion-body-padding-y: 6px !important;
  --bs-accordion-active-color: #FFF !important;
  --bs-accordion-active-bg: #000 !important; }
  :root:root:root .accordion .accordion-header button,
  :root:root:root .accordion-button .accordion-header button {
    font-weight: bolder;
    font-style: italic;
    text-transform: uppercase; }

.accordion {
  margin-bottom: 6px; }
  .accordion .accordion-body {
    padding-bottom: 0px; }

/* search box ( blade ? ) could use some tweaking */
.sfield-wrapper {
  --bs-border-color: #000; }
  .sfield-wrapper .sfield {
    color: #000;
    border-radius: 0px;
    background-color: #FFF; }
    .sfield-wrapper .sfield::placeholder {
      color: #000; }
    .sfield-wrapper .sfield:focus {
      background-color: transparent;
      color: #000;
      outline: none;
      border-color: #000; }
      .sfield-wrapper .sfield:focus::placeholder {
        opacity: 0; }
  .sfield-wrapper .btn {
    border: none;
    background-image: url("/images/icons/search_pos.svg");
    background-repeat: none;
    width: 38px; }
  .sfield-wrapper .btn-light {
    background-image: url("/images/icons/search_neg.svg"); }
  .sfield-wrapper .search {
    color: #FFF;
    background-color: #000;
    border-radius: 0px; }
    .sfield-wrapper .search:hover {
      background-color: #000; }
  .sfield-wrapper .sfield::-ms-input-placeholder {
    /* Edge */
    color: #000; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #FFF;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: 'ClanOT-News';
  text-transform: uppercase;
  background-color: #000;
  background-image: none;
  border: 1px solid #000;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.spinner-border {
  color: #009FE3 !important; }

/* ikon-a ( blade ? ) */
.icon-a {
  color: #000;
  text-decoration: none;
  font-family: 'ClanOT-News';
  height: 24px; }
  .icon-a img {
    height: 24px; }

@media (max-width: 1199.98px) {
  .desktop {
    display: none; } }

@media (min-width: 1200px) {
  .mobile {
    display: none; } }

.menu-item {
  user-select: none;
  font-size: 1rem; }
  .menu-item a {
    color: #000 !important;
    text-decoration: none !important; }
  .menu-item .mp-menu-links {
    display: none; }
  .menu-item .mp-menu-info {
    display: flex;
    font-size: 0.8rem; }

.menu-title {
  font-family: ClanSC-CondUltraItalic;
  font-size: 2rem;
  margin-bottom: -8px; }

main {
  padding-top: 12px; }

.login-page__background, main:has(.proam-title) {
  margin-top: 0px; }

.sc-play a {
  color: #000 !important;
  text-decoration: none !important; }

.sc-play .text-bg-danger {
  display: flex;
  flex-flow: column;
  text-align: center; }

.sc-play .menu-text-mini {
  font-family: ClanOT-CondNews;
  font-size: 0.8rem;
  font-weight: bolder; }

.sc-play .menu-text-date {
  font-family: ClanSC-CondUltra;
  font-size: 3rem;
  margin-top: -32px;
  margin-bottom: -32px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-grow: 1; }

@media (max-width: 1199.98px) {
  .sc-play {
    max-height: 120px;
    margin-bottom: 0.5rem; }
    .sc-play > a > .col-3 {
      max-width: 120px; }
    .sc-play > a > .col-6 {
      flex: 1; }
    .sc-play > a > .text-bg-danger {
      max-width: 120px; } }

.menu-item:active, .menu-item:hover, .menu-item.current {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  --bs-border-opacity: 1;
  border-color: #ff0000 !important;
  border-width: 10px !important; }
  @media (min-width: 1200px) {
    .menu-item:active .mp-menu-links, .menu-item:hover .mp-menu-links, .menu-item.current .mp-menu-links {
      display: flex; }
    .menu-item:active .mp-menu-info, .menu-item:hover .mp-menu-info, .menu-item.current .mp-menu-info {
      display: none; } }

body {
  background-color: #009FE3; }

main {
  background-color: white; }

@media (max-width: 1199.98px) {
  .showMobileMenu {
    height: 100vh; }
    .showMobileMenu #navbarToggleExternalContent {
      display: block; }
    .showMobileMenu .navbar-nav li:last-child {
      display: none; }
  .showMobileMenu ~ main {
    display: none; } }

@media (min-width: 1200px) {
  body {
    background-color: #009FE3;
    flex-direction: row;
    gap: 12px;
    margin: auto;
    margin-top: 110px;
    max-width: 1600px;
    width: 100%; }
    body > div.container {
      max-width: 430px; }
      body > div.container nav.navbar {
        width: 100%;
        height: 110px;
        position: absolute;
        top: 0;
        padding-left: calc(max((100vw - 1600px) / 2, 0px) + var(--bs-gutter-x) * 0.5);
        padding-right: calc(max((100vw - 1600px) / 2, 0px) + var(--bs-gutter-x) * 0.5);
        width: 100vw;
        height: 110px;
        left: 0; }
        body > div.container nav.navbar > div {
          padding: 0; }
        body > div.container nav.navbar .navbar-nav {
          align-items: center; }
        body > div.container nav.navbar .navbar-nav li:last-child {
          display: none; }
        body > div.container nav.navbar .nav-item img, body > div.container nav.navbar .navbar-brand img {
          width: 64px;
          height: 64px; }
        body > div.container nav.navbar #cart {
          width: 50px;
          height: 50px; }
      body > div.container #navbarToggleExternalContent {
        display: block; }
  main {
    background-color: #FFF;
    height: fit-content;
    padding: 12px;
    margin-right: 12px;
    overflow: auto;
    margin-top: 0;
    max-height: calc(100vh - 110px); } }

main:has(.back) {
  background-color: #1A1A1A; }

.back {
  background-color: #1A1A1A;
  color: white;
  height: 100%; }

.proam-title {
  font-size: 2rem;
  font-family: ClanOT-CondUltraItalic; }

.proam-bold {
  font-size: 1rem;
  font-family: ClanOT-CondUltraItalic;
  text-transform: uppercase; }

.pro-am-knapp {
  text-align: start;
  background-color: black;
  border: none;
  color: white; }

.pro-am-bar {
  color: black;
  background-color: #009FE3;
  padding: 0.4em; }

.proam-teampic {
  float: right;
  text-align: right; }
  .proam-teampic .pro-am-teambild {
    width: 70%;
    max-width: 96px;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    object-fit: cover; }

.proam-member > div:first-child {
  width: unset; }

.proam-member > div:last-child {
  flex: 1; }

.pro-am-profilbild {
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  aspect-ratio: 1 / 1; }

.pro-am-smalltext {
  font-size: 0.8rem; }

.proam-small {
  font-size: 10px; }

.blue {
  color: #009FE3; }

.pro-am-teamleader {
  font-size: 13px;
  color: white; }

.pro-am-add {
  font-size: 12px;
  text-align: start;
  background-color: #009FE3;
  border: none;
  color: white; }

.proam-flag {
  width: 80%; }

.proam-pic {
  border-radius: 50%; }

.proam-goodteampfp {
  width: unset;
  padding-right: 8px; }

.search-card {
  font-size: 14px;
  border-bottom: #009FE3 1px solid; }

.proam-decline {
  background-color: #ff0000; }

.dark .sfield {
  border-color: white;
  color: white;
  background-color: #1A1A1A; }
  .dark .sfield::placeholder {
    color: white; }
  .dark .sfield:focus {
    color: white;
    border-color: white; }

.dark .search {
  background-color: white;
  color: black; }
  .dark .search:hover {
    background-color: white; }

.dark .sfield::-ms-input-placeholder {
  /* Edge */
  color: white; }

.proam-input input {
  border-radius: 0;
  border: 0;
  background-color: #009FE3; }
  .proam-input input:focus {
    background-color: #009FE3; }

.proam-file input {
  background-color: #009FE3;
  border: none; }
  .proam-file input:focus {
    background-color: #009FE3; }

.proam-member {
  align-items: center; }

#proam-createbtn {
  border: none;
  background-color: #009FE3;
  color: white;
  padding: 0.3rem;
  width: fit-content; }
  #proam-createbtn:disabled {
    background-color: dimgrey;
    color: linen;
    opacity: 1; }

.proam-btn {
  text-align: center;
  letter-spacing: .1rem; }

.proam-create .ps-1 {
  font-family: "ClanOT-Bold";
  font-size: 1rem; }

#results .search-card div:last-child {
  justify-content: flex-end; }

@media (min-width: 1200px) {
  main {
    padding: 0; }
  main > form > .container, main > .container {
    padding: 24px; }
  .proam-left {
    width: calc(50% - 24px);
    margin-right: 24px; }
  .sfield-wrapper {
    margin-top: 0 !important; } }

.pin-header {
  border-bottom: 1px solid #009FE3;
  border-top: 1px solid #009FE3; }

.pin-image {
  flex: 0 0 20%;
  max-width: 20%;
  justify-content: center;
  height: 75px; }
  .pin-image img {
    height: 75px;
    width: 75px;
    object-fit: contain; }

@media (min-width: 1200px) {
  .pin-image {
    flex: 0 0 10%;
    max-width: 10%; } }

.pin-row {
  min-height: 75px; }

.img-fluid {
  max-height: 75px; }

.sc-profile__top-stats {
  margin-left: -12px;
  margin-right: -12px;
  margin-top: -12px;
  background-color: black;
  color: white;
  padding: 12px;
  /* TODO: adjust top-padding after we get the font */ }
  .sc-profile__top-stats .col {
    text-align: center;
    font-size: 0.8rem; }
    .sc-profile__top-stats .col .sc-number {
      color: #009FE3;
      font-size: 2rem; }

.sc-profile__user .sc-profile__user__profile-picture {
  max-width: 128px;
  display: grid; }
  .sc-profile__user .sc-profile__user__profile-picture > * {
    grid-column: 1;
    grid-row: 1; }
  .sc-profile__user .sc-profile__user__profile-picture > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end; }
  .sc-profile__user .sc-profile__user__profile-picture .sc-profile__user__profile-picture__img {
    width: 100%;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover; }
  .sc-profile__user .sc-profile__user__profile-picture .sc-profile__user__profile-picture__flag {
    width: 44px;
    filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.5));
    /* image-rendering: pixelated; would make the flag look sharper, but our icons are bad, so it looks worse.... */ }

.sc-profile__user .sc-profile__user__details {
  text-transform: uppercase;
  font-family: 'ClanOT-CondUltraItalic';
  display: flex;
  flex-flow: column; }
  .sc-profile__user .sc-profile__user__details p {
    margin: 0;
    color: #009FE3;
    font-size: 2rem;
    margin-bottom: -12px; }
  .sc-profile__user .sc-profile__user__details .sc-profile__user__details__pins {
    flex-grow: 1;
    gap: 4px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    /* ehhhhh */ }
    .sc-profile__user .sc-profile__user__details .sc-profile__user__details__pins img {
      object-fit: contain;
      width: 100%; }
    @media (max-width: 1199.98px) {
      .sc-profile__user .sc-profile__user__details .sc-profile__user__details__pins {
        gap: 8px;
        margin-left: 8px; }
        .sc-profile__user .sc-profile__user__details .sc-profile__user__details__pins img {
          width: 48px;
          height: 48px;
          margin: -16px; } }

.sc-profile__results {
  overflow: hidden; }
  .sc-profile__results .sc-profile__results__result {
    border-bottom: 1px solid #009FE3;
    margin-bottom: 6px;
    padding-bottom: 6px; }
    .sc-profile__results .sc-profile__results__result .col-3 {
      display: flex;
      align-items: center; }
      .sc-profile__results .sc-profile__results__result .col-3 img {
        width: 100%; }
    .sc-profile__results .sc-profile__results__result .col {
      display: flex;
      flex-flow: column wrap; }
      .sc-profile__results .sc-profile__results__result .col div {
        display: flex;
        justify-content: space-between; }
      .sc-profile__results .sc-profile__results__result .col div:nth-child(2) {
        flex: 1; }
    .sc-profile__results .sc-profile__results__result .sc-profile__results__result__tour {
      font-size: 0.8rem;
      color: #ff0000;
      text-align: end; }
    .sc-profile__results .sc-profile__results__result .sc-profile__results__result__title {
      font-size: 1.5rem;
      text-transform: uppercase;
      font-family: 'ClanOT-CondUltraItalic';
      margin-top: -8px; }
    .sc-profile__results .sc-profile__results__result .sc-profile__results__result__place {
      font-size: 1.5rem;
      color: #009FE3; }
    .sc-profile__results .sc-profile__results__result .sc-profile__results__result__pins {
      color: #009FE3; }

.sc-profile__pins .sc-pin {
  height: unset;
  display: flex;
  align-items: center;
  width: 20%; }
  .sc-profile__pins .sc-pin img {
    object-fit: contain;
    width: 100%; }

@media (min-width: 1200px) {
  .sc-profile__user .sc-profile__user__details {
    flex-grow: 1; }
    .sc-profile__user .sc-profile__user__details p {
      font-size: 3rem;
      margin-top: 12px;
      margin-bottom: -8px;
      line-height: 1; }
    .sc-profile__user .sc-profile__user__details .sc-profile__user__details__pins {
      flex-grow: unset;
      margin-top: 24px; }
  .sc-profile__pins .sc-pin {
    width: 10%; }
  .sc-profile__user__profile-picture {
    max-width: 170px; }
  .sc-profile__user__details__pins .sc-pin {
    width: 48px;
    height: 48px; }
  .sc-profile__user__top-stats {
    align-items: flex-end;
    display: flex;
    max-width: 370px; }
    .sc-profile__user__top-stats > div {
      background-color: transparent;
      color: #000; } }

.sc-editProfile .sc-button {
  display: inline-block; }

.sc-editProfile .hide, .sc-editProfile .text--hide {
  display: none; }

.sc-editProfile .sc-profileEdit__pfp {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  overflow: hidden; }
  .sc-editProfile .sc-profileEdit__pfp img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.sc-editProfile #image-preview {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1; }

.sc-editProfile .profile-input {
  border-radius: 0px;
  border: 1px solid black;
  color: black; }

.sc-editProfile .alert {
  border-radius: 0px; }

.sc-results .sc-results__results {
  overflow: hidden; }
  .sc-results .sc-results__results .col-3 {
    display: flex;
    align-items: center; }
    .sc-results .sc-results__results .col-3 img {
      width: 100%;
      max-height: 96px;
      object-fit: contain; }
  .sc-results .sc-results__results .col {
    display: flex;
    flex-flow: column wrap; }
    .sc-results .sc-results__results .col div {
      display: flex;
      justify-content: space-between; }
    .sc-results .sc-results__results .col div:nth-child(2) {
      flex: 1; }
  .sc-results .sc-results__results .sc-results__results__result {
    /* consider renaming this. */
    border-bottom: 1px solid #009FE3;
    margin-bottom: 6px;
    padding-bottom: 6px; }
    .sc-results .sc-results__results .sc-results__results__result:last-child {
      border-bottom: none; }
  .sc-results .sc-results__results .sc-results__results__result__tour {
    color: #ff0000;
    font-size: 0.8rem; }
  .sc-results .sc-results__results .sc-results__results__result__tour__cyan {
    color: #57C2E0;
    font-size: 0.8rem; }
  .sc-results .sc-results__results .sc-results__results__result__title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: 'ClanOT-CondUltraItalic';
    margin-bottom: -4px; }
  .sc-results .sc-results__results .sc-results__results__result__placepins {
    margin-bottom: 4px; }

.sc-results .sc-results__upcoming .sc-results__upcoming__race {
  border-bottom: 1px solid #009FE3;
  margin-bottom: 6px;
  padding-bottom: 6px;
  margin-left: 0;
  margin-right: 0; }
  .sc-results .sc-results__upcoming .sc-results__upcoming__race .col {
    display: flex;
    flex-flow: column; }
  .sc-results .sc-results__upcoming .sc-results__upcoming__race:last-child {
    border-bottom: none; }

.sc-results .sc-results__upcoming .sc-results__upcoming__race__date {
  background-color: #009FE3;
  color: #FFF;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 86px;
  width: 86px; }
  @media (min-width: 1200px) {
    .sc-results .sc-results__upcoming .sc-results__upcoming__race__date {
      height: 96px;
      width: 96px; }
      .sc-results .sc-results__upcoming .sc-results__upcoming__race__date span:last-child {
        font-size: 0.8rem; } }
  .sc-results .sc-results__upcoming .sc-results__upcoming__race__date span:first-child {
    font-size: 3rem;
    font-family: "ClanSC-CondUltra";
    margin-bottom: -12px; }
  .sc-results .sc-results__upcoming .sc-results__upcoming__race__date span:last-child {
    font-family: "ClanOT-CondNews";
    font-size: 0.7rem; }

.sc-results .sc-results__upcoming .sc-results__upcoming__race__tour {
  color: #ff0000;
  font-size: 0.8rem; }

.sc-results .sc-results__upcoming .sc-results__upcoming__race__tour__cyan {
  color: #009FE3;
  font-size: 0.8rem; }

.sc-results .sc-results__upcoming .sc-results__upcoming__race__title {
  flex: 1;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: 'ClanOT-CondUltraItalic'; }

.sc-results .sc-results__upcoming .sc-results__upcoming__race__info {
  font-family: 'ClanOT-News';
  font-size: 0.8rem; }

.sc-results .sc-results__upcoming .sc-results__upcoming__race__share {
  float: right; }

.sc-membership__paydisplay {
  background-color: #000;
  color: #FFF;
  padding: 2px 8px;
  padding-bottom: 0px;
  /* cuz of how font rendering works.... */
  font-size: 1.25rem;
  list-style: none;
  margin-bottom: 6px;
  font-family: 'ClanOT-CondUltraItalic'; }
  .sc-membership__paydisplay span {
    float: right;
    background-color: #B28712;
    height: 100%;
    margin-right: -8px;
    padding: 0px 8px;
    padding-top: 2px;
    margin-top: -2px; }

.sc-membership__payments {
  overflow: hidden; }
  .sc-membership__payments .sc-membership__payments__payment {
    border-bottom: 1px solid #009FE3;
    margin-bottom: 12px;
    padding-bottom: 12px; }
    .sc-membership__payments .sc-membership__payments__payment .sc-membership__payments__payment__date {
      margin-bottom: 6px; }
      .sc-membership__payments .sc-membership__payments__payment .sc-membership__payments__payment__date span {
        float: right; }
    .sc-membership__payments .sc-membership__payments__payment .sc-membership__payments__payment__info {
      display: flex;
      align-items: center;
      height: 40px;
      font-family: "ClanOT-CondUltraItalic";
      font-size: 1.25rem; }
      .sc-membership__payments .sc-membership__payments__payment .sc-membership__payments__payment__info img {
        height: 40px;
        margin-right: 16px; }
      .sc-membership__payments .sc-membership__payments__payment .sc-membership__payments__payment__info span {
        flex: 1; }
      .sc-membership__payments .sc-membership__payments__payment .sc-membership__payments__payment__info .sc-button {
        font-size: 1rem;
        margin-right: 0px; }

.membership-toggle {
  cursor: pointer; }
  .membership-toggle .spinner-border {
    float: right; }

.current-user {
  color: #FFF; }
  .current-user .rank-card__list {
    border-bottom: none; }
    .current-user .rank-card__list > div {
      background-color: #3762A2; }
    .current-user .rank-card__list .rank-card__list__place {
      background-color: #000; }

.current-user-rank {
  background-color: #009FE3; }

.mlr10 {
  margin-left: 12px;
  margin-right: 12px; }

.rank-card__list {
  font-family: 'ClanOT-CondUltraItalic';
  border-bottom: 1px solid #009FE3; }
  .rank-card__list a {
    color: black;
    text-decoration: none; }
  .rank-card__list > div {
    padding-top: 6px;
    padding-bottom: 6px; }
    @media (min-width: 1200px) {
      .rank-card__list > div {
        display: flex;
        align-items: center;
        flex-flow: row wrap; }
        .rank-card__list > div br {
          display: none; } }
  .rank-card__list .rank-card__list__heart {
    cursor: pointer; }
  @media (min-width: 1200px) {
    .rank-card__list .rank-card__list__name {
      justify-content: space-between;
      flex: 1; }
      .rank-card__list .rank-card__list__name span {
        flex: 50%; }
    .rank-card__list .rank-card__list__rank {
      width: 18%;
      display: flex;
      flex-flow: row-reverse wrap;
      justify-content: space-between; }
      .rank-card__list .rank-card__list__rank span:first-child {
        width: 30%; }
      .rank-card__list .rank-card__list__rank span:last-child {
        width: 70%; }
    .rank-card__list.rank-card__list__header .rank-card__list__rank > span:first-child {
      width: calc(30% + 40px);
      margin-right: -40px;
      /* as the heart doesnt have anything in the header, this is okay. hard to fit it all otherwise. */
      z-index: 1; }
    .rank-card__list .rank-card__list__heart {
      text-align: center; }
      .rank-card__list .rank-card__list__heart img {
        max-width: 32px; } }
  .rank-card__list .rank-card__list__name span:last-child,
  .rank-card__list .rank-card__list__rank span:last-child {
    font-family: 'ClanOT-Bold';
    font-size: 0.8rem; }
  .rank-card__list .rank-card__list__nat img {
    width: 100%; }
    @media (max-width: 1199.98px) {
      .rank-card__list .rank-card__list__nat img {
        width: calc(100% + 16px);
        margin-left: -4px;
        /* this is actually not centered, but visually, this looks right */ } }
  @media (max-width: 1199.98px) {
    .rank-card__list .rank-card__list__heart img {
      width: calc(100% + 16px);
      margin-left: -8px; } }
  @media (max-width: 767.98px) {
    .rank-card__list {
      font-size: 12px; }
      .rank-card__list .rank-card__list__name span:last-child, .rank-card__list .rank-card__list__rank span:last-child {
        font-size: 10px; } }
  .rank-card__list.rank-card__list__header {
    border-bottom: none;
    color: #FFF;
    margin-top: 12px; }
    .rank-card__list.rank-card__list__header > div {
      background-color: #009FE3; }
    .rank-card__list.rank-card__list__header .rank-card__list__place {
      background-color: #000; }

.filter-bar {
  display: none; }

label[for="filtercheck"] {
  color: #009FE3;
  text-decoration: underline;
  margin-top: 4px; }

#filtercheck:checked ~ .filter-bar {
  display: block; }

.ranking__filter label {
  color: #009FE3; }

label[for="rank-favorites"] {
  width: 32px;
  color: black;
  margin-top: 12px; }
  label[for="rank-favorites"] #Lager_1 g .st1 {
    display: none; }

#rank-favorites:checked ~ label[for="rank-favorites"] #Lager_1 g .st1 {
  display: block; }

.sc-mymessages__messages .sc-mymessages__messages__message {
  border-bottom: 1px solid #009FE3;
  margin-bottom: 12px;
  padding-bottom: 12px; }
  .sc-mymessages__messages .sc-mymessages__messages__message span {
    display: inline-block; }
  .sc-mymessages__messages .sc-mymessages__messages__message .sc-mymessages__messages__message__datebox {
    color: #009FE3;
    text-decoration: underline;
    /* ????????????????? */
    display: flex;
    align-items: center; }
    .sc-mymessages__messages .sc-mymessages__messages__message .sc-mymessages__messages__message__datebox span {
      flex-grow: 1; }
    .sc-mymessages__messages .sc-mymessages__messages__message .sc-mymessages__messages__message__datebox img {
      height: 32px; }
  .sc-mymessages__messages .sc-mymessages__messages__message .sc-mymessages__messages__message__title {
    font-size: 1.5rem;
    font-family: 'ClanOT-CondUltraItalic'; }
  .sc-mymessages__messages .sc-mymessages__messages__message .sc-mymessages__messages__message__content {
    padding-top: 12px;
    padding-bottom: 12px; }
  .sc-mymessages__messages .sc-mymessages__messages__message .sc-button {
    border: none; }

.sc-admin {
  line-height: 2;
  font-size: 16px; }
  .sc-admin button {
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    background-color: #ddd; }
  .sc-admin * {
    font-size: 16px; }

.pins-form {
  font-size: 16px; }
  .pins-form * {
    font-size: 16px; }

.pins-form .form-group {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #009FE3; }

.pins-form .form-select {
  width: unset;
  padding: 0.3rem;
  text-align: left;
  color: #000;
  text-transform: none;
  background-color: #FFF;
  border: 1px solid #000;
  border-radius: 4px; }

.connect-user .text--hide {
  display: none; }

.login-wrapper {
  background-color: #009FE3;
  font-family: ClanOT-Ultra; }

.login-page__title {
  font-size: 2rem;
  margin-top: 3rem; }

.login-logo {
  height: 160px;
  width: 160px; }
  .login-logo img {
    height: 100%;
    width: 100%; }

.login-btn {
  font-size: 1rem;
  background-color: #009FE3;
  color: white;
  text-decoration: none;
  padding: 10px; }

.login-page__background {
  background-color: #009FE3; }
